<template>
  <div>
    <div style="" class="game-card">
      <div class="game-type">
        <div class="country">
          {{ fixture.country }} •
          {{ shortenName(fixture.tournament, 40) }}
        </div>

        <div class="date">
          <div>
            <span v-show="!live">{{ formatMatchDate(fixture.date) }}</span>
          </div>
          <div v-show="live" class="">
            {{ fixture.match_status }}
            <span> {{ fixture.event_time }} </span>
          </div>
        </div>
      </div>

      <div class="games">
        <div class="px-0 text-dark game-card-teams">
          <router-link
            :to="{
              name: 'match',
              params: {
                game_id: fixture.game_id === undefined ? 0 : fixture.game_id,
                name: toURL(fixture.name),
                status: live ? 'live' : 'prematch',
              },
            }"
            class="match d-block font-weight-bold"
            style="text-decoration: none"
          >
            <div>
              <span
                v-show="live"
                class="score"
                v-text="fixture.home_score"
              ></span>
              {{ shortenName(home_team, 20) }}
            </div>
            <div class="mb-0">
              <span
                v-show="live"
                class="score"
                v-text="fixture.away_score"
              ></span>
              {{ shortenName(away_team, 20) }}
            </div>
          </router-link>
        </div>

        <div class="odds">
          <Odd
            v-for="(o, index) in fixture.highlight_market.outcomes"
            v-bind:key="getKey(o.odd_id)"
            v-bind:id="o.odd_id"
            v-bind:index="index"
            class="col text-center bold"
            v-bind:class="getClasses(o)"
            style="
              margin: 0px !important;
              padding: 2px !important;
              height: fit-content;
            "
            v-bind:sport_id="fixture.sport_id"
            v-bind:boostedGame="fixture.boosted === 1"
            v-bind:match_id="fixture.match_id"
            v-bind:market_name="fixture?.highlight_market?.market_name"
            v-bind:home_team="home_team"
            v-bind:away_team="away_team"
            v-bind:market_id="fixture.highlight_market.market_id"
            v-bind:outcome_id="o.outcome_id"
            v-bind:specifier="fixture.highlight_market.specifier"
            v-bind:active="o.active"
            v-bind:status="fixture.highlight_market.status"
            v-bind:odds="o.odds"
            v-bind:producer_id="fixture.producer_id"
            v-bind:producer_status="fixture.producer_status"
            v-bind:previous_odds="o.previous_odds"
            v-bind:alias="o.alias"
            v-bind:probability="o.probability"
            v-bind:pick="o"
          />
        </div>
      </div>

      <div class="markets">
        <div>
          <div class="sim" v-show="fixture.sim">
            <span> {{ $t("simulatedMatch") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M6.75 1.00002C6.75 1.22202 6.6535 1.42152 6.5 1.55902V2.50002H9C9.39782 2.50002 9.77936 2.65805 10.0607 2.93936C10.342 3.22066 10.5 3.60219 10.5 4.00002V9.00002C10.5 9.39784 10.342 9.77937 10.0607 10.0607C9.77936 10.342 9.39782 10.5 9 10.5H3C2.60218 10.5 2.22064 10.342 1.93934 10.0607C1.65804 9.77937 1.5 9.39784 1.5 9.00002V4.00002C1.5 3.60219 1.65804 3.22066 1.93934 2.93936C2.22064 2.65805 2.60218 2.50002 3 2.50002H5.5V1.55902C5.40675 1.47561 5.33585 1.37021 5.29375 1.2524C5.25164 1.13459 5.23968 1.00812 5.25895 0.88451C5.27821 0.760897 5.32809 0.644066 5.40405 0.544656C5.48 0.445247 5.57962 0.366416 5.69382 0.315344C5.80803 0.264271 5.93319 0.242578 6.05793 0.252241C6.18266 0.261903 6.30299 0.302614 6.40797 0.370666C6.51295 0.438719 6.59923 0.53195 6.65897 0.641872C6.71871 0.751793 6.75 0.874912 6.75 1.00002ZM0 5.00002H1V8.00002H0V5.00002ZM12 5.00002H11V8.00002H12V5.00002ZM4.5 7.25002C4.69891 7.25002 4.88968 7.171 5.03033 7.03035C5.17098 6.8897 5.25 6.69893 5.25 6.50002C5.25 6.30111 5.17098 6.11034 5.03033 5.96969C4.88968 5.82904 4.69891 5.75002 4.5 5.75002C4.30109 5.75002 4.11032 5.82904 3.96967 5.96969C3.82902 6.11034 3.75 6.30111 3.75 6.50002C3.75 6.69893 3.82902 6.8897 3.96967 7.03035C4.11032 7.171 4.30109 7.25002 4.5 7.25002ZM8.25 6.50002C8.25 6.30111 8.17098 6.11034 8.03033 5.96969C7.88968 5.82904 7.69891 5.75002 7.5 5.75002C7.30109 5.75002 7.11032 5.82904 6.96967 5.96969C6.82902 6.11034 6.75 6.30111 6.75 6.50002C6.75 6.69893 6.82902 6.8897 6.96967 7.03035C7.11032 7.171 7.30109 7.25002 7.5 7.25002C7.69891 7.25002 7.88968 7.171 8.03033 7.03035C8.17098 6.8897 8.25 6.69893 8.25 6.50002Z"
                fill="white"
              />
            </svg>
          </div>
          <p v-if="fixture.boosted === 1" class="boosted">
            <span
              ><svg
                style="margin-bottom: -4px"
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="15"
                viewBox="0 0 9 15"
                fill="none"
              >
                <path
                  d="M0.832031 6.5L4.72677 0.5L8.83203 6.69048L6.09519 5.45238L5.88466 10.7857L3.33203 9.5L2.83203 14.5L2.09519 8.02381L3.25308 8.30952V5.54762L0.832031 6.5Z"
                  fill="#CC1717"
                />
              </svg>
            </span>
            {{ $t("boosted") }}
          </p>
        </div>
        <p>
          <router-link
            :to="{
              name: 'match',
              params: {
                game_id: fixture.game_id === undefined ? 0 : fixture.game_id,
                name: toURL(fixture.name),
                status: live ? 'live' : 'prematch',
              },
            }"
            style="text-decoration: none; color: var(--text-color)"
          >
            +{{ fixture.active_markets }} {{ $t("markets") }}</router-link
          >
        </p>
      </div>
    </div>

    <div class="space-1"></div>
  </div>
</template>

<script>
import Odd from "./Odd.vue";

export default {
  components: {
    Odd,
  },
  name: "fixture",
  props: {
    fixture: {
      required: true,
    },
    live: {
      required: false,
      default: false,
    },
    tab: {
      required: false,
      default: "highlights",
    },
  },
  computed: {
    event_time: function () {
      var minutes = parseInt(this.seconds / 60);
      //var seconds = this.seconds % 60;

      var min = minutes;

      if (minutes < 10) {
        min = "0" + min;
      }

      return min + "'";
    },
    sport_name: function () {
      return this.getSportName(this.fixture.sport_id);
    },
    country_name: function () {
      return this.fixture.country;
    },
    home_team: function () {
      return this.getHomeCompetitorName(this.fixture.name);
    },
    away_team: function () {
      return this.getAwayCompetitorName(this.fixture.name);
    },
    sportIcon: function () {
      return this.getSportIcon(this.fixture.sport_id);
    },
    sportAlt: function () {
      switch (this.fixture.sport_id) {
        case 1:
          return "Soccer Icon";

        case 2:
          return "basketball icon";

        case 4:
          return "hockey icon";

        case 5:
          return "tennis icon";

        case 22:
          return "/assets/icons/dart.svg";

        case 20:
          return "/assets/icons/tabletennis.svg";

        case 6:
          return "/assets/icons/handball.svg";

        case 12:
          return "/assets/icons/rugby.svg";

        case 10:
          return "/assets/icons/boxing.svg";

        case 31:
          return "/assets/icons/badminton.svg";

        case 3:
          return "/assets/icons/baseball.svg";
      }
      return "";
    },
  },
  methods: {
    isVisible: function () {
      return (
        this.fixture !== undefined &&
        this.fixture !== null &&
        this.fixture.highlight_market !== undefined &&
        this.fixture.highlight_market !== null
      );
    },

    isLocked: function (o) {
      if (parseInt(o.producer_id) === 1) {
        return (
          parseInt(o.status) > 0 ||
          parseInt(o.active) === 0 ||
          parseInt(o.producer_status) === 0
        );
      } else {
        return parseInt(o.status) > 0 || parseInt(o.active) === 0;
      }
    },

    getKey: function (index) {
      var prefix = "odds-" + index;
      return Math.random()
        .toString(10)
        .replace("0.", "odd-id-" + prefix + "-");
    },

    getV: function (x) {
      return x;
    },
    getHomeCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }

      return matchName.split("vs.")[0];
    },
    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },

    getClasses: function (o) {
      var col = "col-2";
      // if (this.fixture === undefined || this.fixture.highlight_market === undefined) {
      //   return ""
      // }
      if (this.fixture.length == 2) {
        col = "col-3";
      }

      return (
        "" +
        " match-" +
        this.fixture.match_id +
        " producer-" +
        o.producer_id +
        " match-" +
        this.fixture.match_id +
        " market-" +
        o.market_id +
        " outcome-" +
        o.outcome_id +
        " specifier-" +
        o.specifier +
        " " +
        col
      );
    },

    matchURL: function () {
      var game_id = this.fixture.game_id;
      var name = this.toURL(this.fixture.name);

      var url = "/match/" + game_id + "/" + name;
      return url;
    },
    toURL: function (name) {
      if (name === undefined) {
        return "-";
      }

      return name.replace(/[^a-z0-9+]+/gi, "-");
    },
    getOddDirectionClass: function (outcome) {
      if (parseInt(outcome.active) === 0 || parseInt(outcome.status) != 0) {
        return "";
      }

      if (parseFloat(outcome.odd) >= parseFloat(outcome.previous_odds)) {
        return "arrow green up";
      }

      return "arrow red down";
    },
  },
  mounted: function () {
    if (this.fixture.event_time.length > 0) {
      var p = this.fixture.event_time.split(":");
      var minutes = p[0];
      var seconds = p[1];
      seconds = parseInt(seconds);
      seconds = seconds + parseInt(minutes) * 60;
      this.seconds = seconds;
    }

    this.isLive = parseInt(this.producer_id) !== 3;

    if (this.fixture.match_status === "NotStarted") {
      this.upcoming = true;
      this.isLive = false;
      this.to_start = this.timeToStart(this.fixture.date);
    }
  },
  data: function () {
    return {
      color: "",
      seconds: 0,
      to_start: "",
      formattedFixture: "",
      upcoming: "",
      isLive: "",
      odds: "",
      status: "",
      active: "",
    };
  },
};
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
}
.games {
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
  padding: 0;
  gap: 8px;
}
.game-type {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  justify-content: space-between;
}

.country {
  width: 50%;
}

.sim {
  border-radius: 310px;
  background: #8f0dcc;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.1px;
  /* display: inline-block; */
  padding: 2px 8px;
  /* width: 30px; */
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2px;
}
.sim span {
  color: #fff !important;
}
.sim svg {
  color: #fff !important;
  margin-left: 4px;
}
.date {
  width: 50%;
  display: flex;
  text-align: right;
  gap: 8px;
  justify-content: end;
  flex-wrap: wrap;
}
.date span {
  /* color: #545151; */
  color: var(--sub-text-color);
}

.game-type > div,
.game-type > div span {
  padding: 0;
  margin: 0;
  color: var(--sub-text-color);
}
.game-card-teams {
  width: 40%;
}
.odds {
  width: 60%;
}
.match > div {
  line-height: 12px;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
  /* color: #0e0d0d; */
  color: var(--text-color);
}
.odds {
  display: flex;
  gap: 0.2rem;
  flex-grow: 1;
}

.odds > div {
  flex: 1;
  max-width: none;
}
.game-card {
  flex-direction: column;
  gap: 8px;
  padding: 8px 20px;
  border-bottom: solid var(--fixture-border) 1px;
}
.markets {
  text-align: right;
  justify-content: end;
  /* color: #1c1b1b;
  color: red; */
  color: var(--text-color);
  font-weight: 700;
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
}
.boosted {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 310px;
  /* background: #f5f5f5;
  color: #140202; */
  background-color: var(--background-color);
  color: var(--text-color);

  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.1px;
}
</style>
