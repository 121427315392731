<template>
  <div v-show="!hidden" class="">
    <fixture v-bind:fixture="fixture" v-bind:live="live"></fixture>
  </div>
</template>

<script>
import fixture from "./Fixture.vue";

export default {
  name: "Highlight",
  // mounted: function () {
  //   console.log("inner highlight");
  // },
  methods: {
    setBusy: function () {
      this.busy = true;
    },
    unBusy: function () {
      this.busy = false;
    },
  },
  components: {
    fixture,
  },
  data: function () {
    return {
      busy: false,
      hidden: false,
    };
  },
  props: {
    fixture: {
      required: true,
    },
    live: {
      required: false,
      default: false,
    },
  },
};
</script>
